const variantName = 'footerV6'

export default {
  [variantName]: {
    // ?============================
    // ?======  Containers =========
    // ?============================

    container: {
      paddingX: [4, '', '', 4],
      paddingY: 10,
      backgroundColor: 'dark',
      color: '#bcbcbc',
      flexDirection: ['column', '', '', 'row'],
      flexWrap: 'wrap',
      justifyContent: ['', '', '', 'center'],
      alignItems: ['center', '', '', 'flex-start'],
      '.locationSwitcherV1': {
        display: 'none'
      },
      '.aboutColumn': {
        padding: ['', '', '', '2rem 3rem']
      },
      '.css-obsdbn': {
        marginRight: '0!important'
      },
      // ?== Nested Elements ==
      '.ctct-form-custom': {
        h2: {
          display: 'none'
        },
        p: {
          display: 'none'
        }
      },
      '.ctct-inline-form': {
        width: '100%',
        marginTop: '1.5rem'
      },
      '.grecaptcha-badge': {
        display: 'none'
      },
      'div.ctct-form-embed form.ctct-form-custom input.ctct-form-element': {
        background: 'transparent',
        backgroundColor: 'transparent!important',
        borderTop: 'none',
        borderLeft: 'none',
        borderRight: 'none',
        borderColor: '#BCBCBD',
        boxShadow: 'none!important',
        width: '100%',
        outline: 'none!important',
        color: 'primary',
        borderRadius: 0,
        fontSize: '16px',
        '&:focus': {
          outline: 'none!important'
        }
      },
      '.ctct-form-footer': {
        display: 'none'
      },
      'div.ctct-form-embed div.ctct-form-defaults': {
        padding: 0
      },
      'div.ctct-form-embed div.ctct-form-defaults': {
        backgroundColor: 'rgba(215, 211, 204)'
      },
      '.ctct-form-defaults': {
        backgroundColor: 'rgba(215, 211, 204)!important'
      },
      '.ctct-form-embed.form_0 .ctct-form-custom .ctct-form-button': {
        display: 'none'
      },
      '.ctct-form-embed form.ctct-form-custom label.ctct-form-label': {
        display: 'none'
      },
      '.multiButtonContainer': {
        margin: '0rem 0rem',
        width: '100%',
        a: {
          width: ['', '', '', '50%'],
          color: '#bcbcbc',
          margin: ['0.25rem', '', '', '0rem'],
          border: 'none',
          textAlign: ['', '', '', 'left'],
          padding: ['', '', '', '0.75rem 0rem'],
          fontWeight: 300,
          ':hover': {
            backgroundColor: 'transparent',
            opacity: '0.5'
          }
        }
      },
      '> div:nth-of-type(1)': {
        borderRight: ['', '', '', '1px solid'],
        padding: ['', '', '', '2rem 3rem 2rem 2rem']
      },
      '.contactDetails-container': {
        textAlign: ['center', '', '', 'left'],
        '.text': {
          margin: ['', '', '', '0.5rem 0rem'],
          fontSize: '1rem'
        }
      },
      '.socialContainer': {
        margin: ['1rem', '', '', '1rem 0rem'],
        svg: {
          marginRight: '0.5rem',
          width: '25px',
          height: '25px'
        }
      }
    },

    column: {
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: ['center', '', '', 'flex-start'],
      width: ['', '', '', ''],
      padding: ['1rem 0rem', '', '1.5rem'],
      textAlign: 'left',
      marginBottom: ['', '', '', '1rem'],
      maxWidth: '350px'
    },

    // ?============================
    // ?=========  Texts  ==========
    // ?============================

    sectionHeading: {
      marginBottom: '1rem',
      paddingBottom: '1rem',
      borderBottom: '1px solid #bcbcbc',
      fontFamily: 'heading',
      fontWeight: '300',
      textTransform: 'uppercase'
    },

    aboutText: {
      color: '#bcbcbc',
      textAlign: ['center', '', '', 'left']
    },

    ctaButton: {
      variant: 'buttons.primary',
      marginTop: '1rem',
      padding: '0rem',
      color: 'primary',
      backgroundColor: 'transparent',
      border: 'none',
      ':hover': {
        color: 'white',
        textDecoration: 'underline'
      }
      // borderColor: '#bcbcbc',
      // borderRadius: '30px',
    },

    // ?============================
    // ?=========  Logos  ==========
    // ?============================

    logo: {
      maxHeight: '150px',
      filter: 'brightness(0) invert(1)',
      marginBottom: '3rem'
    },

    // ?============================
    // ?=========  Widgets  ========
    // ?============================

    quote: {
      margin: ['0rem auto', '', '', '0rem'],
      fontSize: '1rem',
      textAlign: ['center', '', '', 'left'],
      padding: '1rem 0rem',
      color: '#bcbcbc'
    },

    poweredBy: {
      // display: 'flex',
      display: 'none !important',
      margin: '0rem 0rem 1rem',
      flexDirection: 'column',
      alignItem: 'center',
      '.gonationLogo': {
        justifyContent: ['center', '', '', 'flex-start'],
        alignItems: 'center',
        display: 'flex',
        svg: {
          height: '1.25rem',
          width: 'auto'
        },
        filter: 'brightness(0) invert(1)',
        margin: '0rem',
        maxWidth: 'unset !important'
      }
    },
    poweredByText: {
      fontSize: '0.9rem'
    }
  }
}
